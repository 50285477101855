// styles
import './styles/global.css'
// toast
import 'react-toastify/dist/ReactToastify.css';
// splide styles
import '@splidejs/react-splide/css/core';
import '@splidejs/react-splide/css';
// bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import React,{ StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient,QueryClientProvider } from '@tanstack/react-query';
import { GoogleOAuthProvider } from '@react-oauth/google';
import UserContextProvider from './context/useUserContext';
import TagManager from 'react-gtm-module'
import { clarity } from 'react-microsoft-clarity';
import App from './App';
// import disableDevtool from 'disable-devtool';
// disableDevtool()

clarity.init( 'l0khgpr113' )
const tagManagerArgs = { gtmId: 'G-7B0P2BC34R' }
TagManager.initialize( tagManagerArgs )

const queryClient = new QueryClient()


const root = ReactDOM.createRoot( document.getElementById( 'root' ) );
root.render(
  <GoogleOAuthProvider clientId="298530437939-kl2sfid3401cjkn7jdjh77mjk5srgg0q.apps.googleusercontent.com">
    <QueryClientProvider client={ queryClient }>
      <UserContextProvider>
        <BrowserRouter>
          <StrictMode>
            <App />
          </StrictMode>
        </BrowserRouter>
      </UserContextProvider>
    </QueryClientProvider>
  </GoogleOAuthProvider>
);

