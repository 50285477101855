import { keepPreviousData,useInfiniteQuery } from '@tanstack/react-query';
import React,{ useRef,useEffect,useState } from 'react';
import { getReels } from '../../services/reels';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';


const YouTubeShort = ( { videoId,onInViewChange } ) => {
    const iframeRef = useRef( null );
    const { ref,inView } = useInView( {
        threshold: 0.5, // Adjust as necessary to determine visibility threshold
        triggerOnce: false,
    } );

    useEffect( () => {
        if ( iframeRef.current ) {
            const src = `https://www.youtube.com/embed/${videoId}?autoplay=${inView ? 1 : 0}&controls=0&modestbranding=1&rel=0&loop=1&playlist=${videoId}`;
            iframeRef.current.src = src;
        }

        if ( onInViewChange ) {
            onInViewChange( inView );
        }
    },[ videoId,inView,onInViewChange ] );

    return (
        <div ref={ ref } className="relative h-screen w-full md:max-w-[500px]  flex items-center justify-center bg-black">
            <iframe
                ref={ iframeRef }
                className="w-full h-full"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title={ `YouTube video player - ${videoId}` }
            ></iframe>
        </div>
    );
};

// export default YouTubeShort;

const shortsData = [
    {
        id: 1,
        videoId: 'dQw4w9WgXcQ', // Example video ID
    },
    {
        id: 2,
        videoId: '9bZkp7q19f0', // Example video ID
    },
    // Add more shorts data here
];

const ShortsList = () => {
    const [ currentShort,setCurrentShort ] = useState( 0 );

    const { ref,inView } = useInView()

    const { id } = useParams()

    const handleScroll = () => {
        const currentIndex = Math.round( window.scrollY / window.innerHeight );
        setCurrentShort( currentIndex );
    };

    useEffect( () => {
        window.addEventListener( 'scroll',handleScroll );
        return () => {
            window.removeEventListener( 'scroll',handleScroll );
        };
    },[] );


    const {
        status,
        data,
        error,
        isFetching,
        isFetchingNextPage,
        // isFetchingPreviousPage,
        fetchNextPage,
        // fetchPreviousPage,
        hasNextPage,
        // hasPreviousPage,
    } = useInfiniteQuery( {
        queryKey: [ `reels-infinite-scroll-modal` ],
        // initialData: {},
        refetchOnWindowFocus: false,
        placeholderData: keepPreviousData,
        queryFn: async ( { pageParam = 1 } ) => {
            let limit = 2
            const reels = await getReels( {
                body: {
                    type: "reels",
                    value: "",
                    pageno: pageParam,
                    pagesize: limit,
                },
            } );
            return {
                reels: {
                    page: reels.data.pageno, // "1",
                    limit: reels.data.pagesize, // "10",
                    totalData: reels.data.totalDataCount, // 742,
                    totalPages: reels.data.totalPages, // 74,
                    data: reels?.data?.data,
                },
                limit: limit,
                page: pageParam || 1,
                offset: pageParam && pageParam > 0 ? pageParam * limit : 0,
            };
        },
        getPreviousPageParam: ( firstPage,pages ) => {
            const nextPage = firstPage ? firstPage?.page + 1 : 0;
            return nextPage;
        },
        getNextPageParam: ( lastPage,pages ) => {
            const nextPage = lastPage ? lastPage?.page + 1 : 0;
            return nextPage;
        },
    } );

    useEffect( () => {
        if ( inView ) {
            fetchNextPage()
        }
    },[ inView ] )

    return (
        <div className="snap-y snap-mandatory overflow-y-scroll h-screen ">
            { data?.pages?.length > 0 && data?.pages?.map( ( page,index ) => (
                page?.reels?.data?.length > 0 && page?.reels?.data?.map( ( reel,index ) => (
                    <div key={ reel.rid } className="snap-start h-screen w-full flex justify-center">
                        <YouTubeShort videoId={ reel.link.split( '/' ).pop() } isActive={ index === currentShort } />
                    </div>
                ) )
            ) ) }
            <div ref={ ref } className=' py-10 text-center'>
                loading...
            </div>
            {/* { shortsData.map( ( short,index ) => (
                <div key={ short.id } className="snap-start h-screen w-full">
                    <YouTubeShort videoId={ short.videoId } isActive={ index === currentShort } />
                </div>
            ) ) } */}
        </div>
    );
};

export default ShortsList;
