import React,{ Suspense } from 'react';
import { Route,Routes,useLocation } from 'react-router-dom';
import useScrollTop from './hooks/useScrollTop';
import { routes } from './routes/routes';
import { Spinner } from 'react-bootstrap';
import './App.css';

function App () {
  useScrollTop()

  return (
    <div>
      <Suspense fallback={
        <div className='min-h-dvh grid place-items-center'><Spinner /></div>
      }>
        <Routes>
          { routes.map( ( route,index ) => (
            <Route key={ index } path={ route.path } element={ route.element } />
          ) ) }
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
