import React from 'react';
import { Navigate } from 'react-router-dom';
import ShortsList from '../pages/reel-details/GptReelPlayer';

// Lazy-loaded components
const Discover = React.lazy(() => import('../pages/discover/Discover'));
const AiContent = React.lazy(() => import('../pages/ai-content/AiContent'));
const AiNews = React.lazy(() => import('../pages/ai-news/AiNews'));
const AiTools = React.lazy(() => import('../pages/ai-tools/AiTools'));
const Blogs = React.lazy(() => import('../pages/blogs/Blogs'));
const PromptLibrary = React.lazy(() => import('../pages/prompt-library/PromptLibrary'));
const NewsDetails = React.lazy(() => import('../pages/news-details/NewsDetails'));
const BlogsDetails = React.lazy(() => import('../pages/blogs-details/BlogsDetails'));
const ToolDetails = React.lazy(() => import('../pages/tool-details/ToolDetails'));
const PromptDetails = React.lazy(() => import('../pages/prompt-details/PromptDetails'));
const Login = React.lazy(() => import('../pages/login/Login'));
const GuideDetails = React.lazy(() => import('../pages/guide-details/GuideDetails'));
const Payment = React.lazy(() => import('../pages/payment/Payment'));
const MidJourneyDepth = React.lazy(() => import('../containers/guide-details/more-card-midjourney/midjourner-depth'));
const MidjourneyUsage = React.lazy(() => import('../containers/guide-details/more-card-midjourney/midjourney-usage'));
const DepthCard = React.lazy(() => import('../containers/guide-details/more-card-all-gmail-marketing/depthCard'));
const UsageCard = React.lazy(() => import('../containers/guide-details/more-card-all-gmail-marketing/usageCard'));
const FollowUp = React.lazy(() => import('../containers/guide-details/more-card-all-gmail-marketing/followUp'));
const PersonaCard = React.lazy(() => import('../containers/guide-details/more-card-all-gmail-marketing/personaCard'));
const StructurizeCard = React.lazy(() => import('../containers/guide-details/more-card-all-gmail-marketing/structurizeCard'));
const BestverbCard = React.lazy(() => import('../containers/guide-details/more-card-all-gmail-marketing/bestverbsCard'));
const AZDepth = React.lazy(() => import('../containers/guide-details/more-card-a-z-marketing/azdepth'));
const AZUsage = React.lazy(() => import('../containers/guide-details/more-card-a-z-marketing/azusage'));
const AZFollowup = React.lazy(() => import('../containers/guide-details/more-card-a-z-marketing/azfollowup'));
const PromptsByTag = React.lazy(() => import('../containers/guide-details/prompts-by-tag/prompts-by-tag'));
const ReelDetails = React.lazy(() => import('../pages/reel-details/ReelDetails'));
const ComingSoon = React.lazy(() => import('../pages/coming-soon/ComingSoon'));
const SubmitTool = React.lazy(() => import('../pages/submit-tool/SubmitTool'));
const About = React.lazy(() => import('../pages/about/About'));
const Apps = React.lazy(() => import('../pages/apps/Apps'));
const Guides = React.lazy(() => import('../pages/guides/Guides'));
const FAQs = React.lazy(() => import('../pages/faqs/FAQs'));
const PrivacyPolicy = React.lazy(() => import('../pages/privacy-policy/PrivacyPolicy'));
const TermsOfService = React.lazy(() => import('../pages/terms-of-service/TermsOfService'));
const Contact = React.lazy(() => import('../pages/contact/Contact'));
const SignUp = React.lazy(() => import('../pages/sign-up/SignUp'));
const Profile = React.lazy(() => import('../pages/profile/Profile'));
const Saved = React.lazy(() => import('../pages/saved/Saved'));
const PaymentList = React.lazy(() => import('../pages/payment-list/PaymentList'));
const Pricing = React.lazy(() => import('../pages/pricing/Pricing'));
const SubscribeNewsletter = React.lazy(() => import('../pages/subscribe-newsletter/SubscribeNewsletter'));
const ChatGpt = React.lazy(() => import('../pages/chatgpt/ChatGpt'));
const EditProfile = React.lazy(() => import('../pages/edit-profile/EditProfile'));
const CancellationAndRefund = React.lazy(() => import('../pages/cancellation-and-refund/CancellationAndRefund'));
const Feedback = React.lazy(() => import('../pages/feedback/Feedback'));
const VideoDetails = React.lazy(()=> import('../pages/ai-content/VideoDetails'));

export const routes = [
    { path: '/login', element: <Login /> },
    { path: '/sign-up', element: <SignUp /> },
    // Search links
    // { path: '/', element: <Discover /> },
    // { path: '/discover', element: <Discover /> },
    { path: '/', element: <Navigate to="/ai-content/videos" replace /> },
    { path: '/ai-content', element: <Navigate to="/ai-content/videos" replace /> },
    { path: '/ai-content/:tab', element: <AiContent /> },
    // { path: '/ai-content', element: <Navigate to="/ai-content/all" replace /> },
    { path: '/ai-news', element: <AiNews /> },
    { path: '/blogs', element: <Blogs /> },
    { path: '/ai-tools', element: <AiTools /> },
    { path: '/prompt-library', element: <PromptLibrary /> },
    { path: '/guides', element: <Guides /> },
    { path: '/apps', element: <Apps /> },
    // Search links details
    { path: '/news-details', element: <NewsDetails /> },
    { path: '/blogs/:id', element: <BlogsDetails /> },
    { path: '/ai-tools/:id', element: <ToolDetails /> },
    { path: '/prompt-library/:id', element: <PromptDetails /> },
    { path: '/prompt-library/:id/chatgpt', element: <ChatGpt /> },
    { path: '/guides/:id', element: <GuideDetails /> },
    { path: '/ai-content/:id', element: <ReelDetails /> },
    { path: '/ai-news/:id', element: <NewsDetails /> },
    { path: "/payment", element: <Payment /> },
    { path: "/guides/prompts-by-tag", element: <PromptsByTag /> },
    
    { path: "/ai-reels/player", element: <ShortsList /> },

    // Midjourney learnmore card pages
    { path: "/midjourneryDepth", element: <MidJourneyDepth /> },
    { path: "/midjourneryUsage", element: <MidjourneyUsage /> },
    // All Email Marketing learnmore card pages
    { path: "/depthCard", element: <DepthCard /> },
    { path: "/usageCard", element: <UsageCard /> },
    { path: "/followupCard", element: <FollowUp /> },
    { path: "/personaCard", element: <PersonaCard /> },
    { path: "/structurizeCard", element: <StructurizeCard /> },
    { path: "/bestverbsCard", element: <BestverbCard /> },
    // A-Z marketing learnmore card pages
    { path: "/azdepth", element: <AZDepth /> },
    { path: "/azusage", element: <AZUsage /> },
    { path: "/azfollowup", element: <AZFollowup /> },
    // Header links
    { path: "/about", element: <About /> },
    { path: "/pricing", element: <Pricing /> },
    { path: "/contact", element: <Contact /> },
    { path: "/FAQ", element: <FAQs /> },
    { path: "/help", element: <ComingSoon /> },
    { path: "/feedback", element: <Feedback /> },
    // { path: "/submit-prompt", element: <SubmitPrompt /> }, 
    // { path: "/submit-user-tool", element: <SubmitTool /> }, 
    { path: "/submit-tool", element: <SubmitTool /> },
    { path: "/profile", element: <Profile /> },
    { path: "/profile/edit", element: <EditProfile /> },
    { path: "/saved", element: <Saved /> },
    { path: "/payment-list", element: <PaymentList /> },
    { path: "/subscribe-newsletter", element: <SubscribeNewsletter /> },
    // Policies
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
    { path: "/terms-of-service", element: <TermsOfService /> },
    { path: "/cancellation", element: <CancellationAndRefund /> },
    { path: "/comming-soon", element: <ComingSoon /> },
];