import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { axiosConfig } from './config'

export const getReels = ( { body } ) => axiosConfig.post( `/Reels/Reels/GetData`,body )

export const useGetReelsQuery = ( { page = 1,limit = 10,type = 'all',value = '' } ) => useQuery( {
    queryKey: [ `reelsapi-${page}-${limit}-${type}-${value}` ],
    initialData: [],
    refetchOnWindowFocus: false,
    placeholderData:keepPreviousData,
    queryFn: async () => {
        const res = await getReels( {
            body: {
                type: type,
                value: value,
                pageno: page,
                pagesize: limit
            }
        } )
        return {
            page: res.data.pageno, // "1",
            limit: res.data.pagesize, // "10",
            totalData: res.data.totalDataCount, // 742,
            totalPages: res.data.totalPages, // 74,
            data: res?.data?.data
            // .map( item => ( {
            //     id: item?.rid, // "381ddef9-8c5c-48fe-a743-7897f3652a51",
            //     channel: item?.channelName, // "UP 51 RIDER",
            //     image: item?.thumbnail, // "https://i.ytimg.com/vi/wx2cW6vuSTQ/hqdefault.jpg",
            //     title: item?.title, // "#petrol #petrolprice #petrolpump #truck #youtubeshorts #shortsfeed #shorts#trending #vehicles #viral",
            //     video: item?.link, // "https://www.youtube.com/shorts/wx2cW6vuSTQ",
            //     duration: item?.duration, // "13",
            //     like: item?.rLike, // 0,
            //     dislike: item?.rDislike, // 0,
            //     date: item?.postDate, // "2024-01-03",
            //     tag: item?.tag, // "#artificialintelligence"
            //     type: item?.type, // "#artificialintelligence"
            // } ) )
        }
    },
} )

export const useGetReelTagsQuery = ( { page = 1,limit = 10,type = 'taglist',value = '' } ) => useQuery( {
    queryKey: [ `reeltagsapi-${value}-${page}-${limit}-${type}` ],
    initialData: [],
    refetchOnWindowFocus: false,
    placeholderData:keepPreviousData,
    queryFn: async () => {
        const res = await getReels( {
            body: {
                type: type,
                value: value,
                pageno: page,
                pagesize: limit
            }
        } )
        let data = res?.data?.data
        // .map( item => ( {
        //     name: item?.tag.split( ',' )
        // } ) )
        // console.log( data );
        return data
        // return [ { name: 'all' } ].concat( ...( res?.data?.data?.map( item => ( {
        //     name: item?.tag
        // } ) ) ) )
    },
} )

export const useGetReelsByIdQuery = ( { id = '' } ) => useQuery( {
    queryKey: [ `reelsapi-${id}` ],
    initialData: [],
    refetchOnWindowFocus: false,
    placeholderData:keepPreviousData,
    queryFn: async () => {
        const res = await getReels( {
            body: {
                type: 'byid',
                value: id,
                pageno: 1,
                pagesize: 1
            }
        } )
        return res?.data?.data[ 0 ]
        // ( {
        //     rid: res?.data?.data[ 0 ].rid, // "381ddef9-8c5c-48fe-a743-7897f3652a51",
        //     channelName: res?.data?.data[ 0 ].channelName, // "UP 51 RIDER",
        //     thumbnail: res?.data?.data[ 0 ].thumbnail, // "https://i.ytimg.com/vi/wx2cW6vuSTQ/hqdefault.jpg",
        //     title: res?.data?.data[ 0 ].title, // "#petrol #petrolprice #petrolpump #truck #youtubeshorts #shortsfeed #shorts#trending #vehicles #viral",
        //     link: res?.data?.data[ 0 ].link, // "https://www.youtube.com/shorts/wx2cW6vuSTQ",
        //     duration: res?.data?.data[ 0 ].duration, // "13",
        //     rLike: res?.data?.data[ 0 ].rLike, // 0,
        //     rDislike: res?.data?.data[ 0 ].rDislike, // 0,
        //     postDate: res?.data?.data[ 0 ].postDate, // "2024-01-03",
        //     tag: res?.data?.data[ 0 ].tag, // "#artificialintelligence"
        //     type: res?.data?.data[ 0 ].type, // "#artificialintelligence"
        // } )
    },
} )

// youtube
export const useGetYoutubeQuery = ( { page = 1,limit = 10,type = 'video',value = '' } ) => useQuery( {
    queryKey: [ `reelsapi-${page}-${limit}-${type}-${value}` ],
    initialData: [],
    refetchOnWindowFocus: false,
    placeholderData:keepPreviousData,
    queryFn: async () => {
        const res = await getReels( {
            body: {
                type: type,
                value: value,
                pageno: page,
                pagesize: limit
            }
        } )
        return {
            page: res.data.pageno, // "1",
            limit: res.data.pagesize, // "10",
            totalData: res.data.totalDataCount, // 742,
            totalPages: res.data.totalPages, // 74,
            data: res?.data?.data
            // .map( item => ( {
            //     tweetid: item?.tweetid, //  : "99f85d6b-7aa0-4957-b542-00b063168900",
            //     name: item?.name, //  : "@technology",
            //     username: item?.username, //  : "JohnsonUSPres24",
            //     text: item?.text, //  : "And Zuckerberg is still going to steal our elections through blocking Black campaigns, shadowing our business pages, and event payments will be blocked. Israel's legacy. Johnson will restore fairness. https://t.co/xw05AlZIoR… via @technology https://t.co/MEd36aHpxh",
            //     tweetURL: item?.tweetURL, //  : "https://twitter.com/JohnsonUSPres24/status/1753300440140464435/photo/1",
            //     tweetviews: item?.tweetviews, //  : "19",
            //     mediaurl: item?.mediaurl, //  : "https://pbs.twimg.com/media/GFT5dOsbIAA5bAB.png",
            //     tweetdate: item?.tweetdate, //  : null,
            //     viewcount: item?.viewcount, //  : 0
            //     type: item?.type, //  : 0
            //     profilebanner: item?.profilebanner
            // } ) )
        }
    },
} )

// "pageno": "1",
// "pagesize": "10",
// "totalDataCount": 100,
// "totalPages": 10,
// "data": [
//     {
//         "tweetid": "99f85d6b-7aa0-4957-b542-00b063168900",
//         "name": "@technology",
//         "username": "JohnsonUSPres24",
//         "text": "And Zuckerberg is still going to steal our elections through blocking Black campaigns, shadowing our business pages, and event payments will be blocked. Israel's legacy. Johnson will restore fairness. https://t.co/xw05AlZIoR… via @technology https://t.co/MEd36aHpxh",
//         "tweetURL": "https://twitter.com/JohnsonUSPres24/status/1753300440140464435/photo/1",
//         "tweetviews": "19",
//         "mediaurl": "https://pbs.twimg.com/media/GFT5dOsbIAA5bAB.png",
//         "tweetdate": null,
//         "viewcount": 0
//     },

