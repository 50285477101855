import axios from 'axios'
export const axiosConfig = axios.create( {
  baseURL: "https://api.prompitup.com/api",
  // withCredentials:true,
  // baseURL: '/api',
} )

export const axiosPaymentConfig = axios.create( {
  baseURL: 'https://payment.prompitup.com/prompitup',
  // withCredentials:true,
} )