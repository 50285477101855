import { createContext,useState } from "react";

export const UserContext = createContext()


const UserContextProvider = ( props ) => {
    const userdata = JSON.parse( localStorage.getItem( 'userDetails' ) )
    const [ userDetails,setUserDetails ] = useState( userdata )

    // const navigate = useNavigate()

    // const { pathname } = useLocation()

    // if ( pathname !== '/' && !userDetails ) {
    //     navigate( '/', { replace: true } )
    //     console.log( pathname,userDetails,pathname !== '/' && !userDetails );
    // }

    return ( <>
        <UserContext.Provider value={ { userDetails,setUserDetails } }>
            { props.children }
        </UserContext.Provider>
    </> )
}

export default UserContextProvider